<template>
    <pagination :response="response" :filterAction="filterAction" title="Leave Requests" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :entities="leaveRequests">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">User
            </th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">Start Date
            </th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">End Date
            </th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">Requested Date
            </th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">Requested Days
            </th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">Project
            </th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">Unpaid
            </th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">Status
            </th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">Reason
            </th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">Actions
            </th>
        </template>
        <template v-slot:items>
            <template v-if="leaveRequests && leaveRequests.size > 0">
                <template v-if="leaveRequests.size > 0" v-for="leaveRequest in leaveRequests">
                    <tr >
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                            {{leaveRequest.entity.properties.get('user')}}<br>
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                            {{leaveRequest.entity.properties.get('start_date')}}<br>
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                            {{leaveRequest.entity.properties.get('end_date')}}<br>
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                            {{leaveRequest.entity.properties.get('requested_date')}}<br>
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                            {{leaveRequest.entity.properties.get('days_requested')}}<br>
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                            {{leaveRequest.entity.properties.get('project')}}<br>
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                            {{leaveRequest.entity.properties.get('is_unpaid')}}<br>
                        </td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                            {{leaveRequest.entity.properties.get('status')}}<br>
                        </td>
                        <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                            {{leaveRequest.entity.properties.get('reason')}}<br>
                        </td>
                        <td class="whitespace-normal px-3 py-4 text-sm text-v3-gray-800">
                            <confirmation-action additionalButtonClasses="" v-if="leaveRequest.entity.actions.get('approve')" :action="leaveRequest.entity.actions.get('approve')"
                                                 actionKey="approve"
                                                 :onSuccessPath="$router.currentRoute.fullPath"
                                                 button-type="positive">
                            </confirmation-action>
                            <form-action additionalButtonClasses="mt-2" v-if="leaveRequest.entity.actions.get('reject')" :action="leaveRequest.entity.actions.get('reject')"
                                         actionKey="reject"
                                         :onSuccessPath="$router.currentRoute.fullPath"
                                         buttonType="negative">

                            </form-action>
                            <confirmation-action additionalButtonClasses="mt-2" v-if="leaveRequest.entity.actions.get('delete')" :action="leaveRequest.entity.actions.get('delete')"
                                                 actionKey="delete"
                                                 :onSuccessPath="$router.currentRoute.fullPath"
                                                 button-type="negative">
                            </confirmation-action>
                        </td>
                    </tr>
                    <tr v-if="leaveRequest.entity.properties.get('rejection_note')" class="hover:bg-secondary-lightbacka cursor-pointer">
                        <td v-if="leaveRequest.entity.properties.get('rejection_note')" colspan="10" class="bg-error text-white whitespace-nowrap py-2 pl-4 text-sm sm:pl-6 font-bold" >
                            Rejection Note: {{leaveRequest.entity.properties.get('rejection_note')}}
                        </td>
                    </tr>
                </template>
            </template>
            <tr v-else>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-center" colspan="5">
                    No leave requests found.
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";

export default {
  name: 'LeaveRequestsApprovalsPagination',
    components: {ConfirmationAction, FormAction, Pagination},
  props: {
    leaveRequests: {},
      collapsable:{
        type: Boolean,
          default: false
      },
      open:{
        type:Boolean,
          default: false
      },
      response: {
        type: Object,
          default: null
      }
  },
    computed:{
      filterAction(){
          if(!this.response){
              return null;
          }
          return this.response.body.actions.filter(action => action.name === 'filter').first();
      }
    },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
