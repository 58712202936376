<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>Hub</template>
            <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link" @responseLoaded="loadApprovals">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <Hr-navigation :links="slotProps.links"/>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-y-auto">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                        <div class="p-2">
                                            <leave-requests-approvals-pagination class="mb-4" v-if="leaveRequests" :leaveRequests="leaveRequests" :response="response" :collapsable="false" :open="leaveRequestsOpen" @toggleOpen="toggleLeaveRequestsOpen">
                                                <template v-slot:additional-buttons>
                                                    <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter')">
                                                        <create-holiday-request-form v-if="actionWithFields[0] === 'request-holiday'"
                                                                                     class="action-with-fields" :action="actionWithFields[1]"
                                                                                     :actionKey="actionWithFields[0]"
                                                                                     :redirectOnSuccess="true"
                                                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                                        ></create-holiday-request-form>
                                                        <form-action v-else
                                                                     class="action-with-fields" :action="actionWithFields[1]"
                                                                     :actionKey="actionWithFields[0]"
                                                                     :redirectOnSuccess="true"
                                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                                        ></form-action>
                                                    </div>
                                                </template>
                                            </leave-requests-approvals-pagination>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>


                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import CreateHolidayRequestForm from "@/v3/components/forms/CreateHolidayRequestForm.vue";
import FetchSirenRoot from '@/components/FetchSirenRoot';
import FormAction from "@/v3/components/forms/FormAction.vue";
import HrNavigation from "@/v3/components/navigation/HrNavigation.vue";
import LeaveRequestsApprovalsPagination from "@/v3/components/pagination/LeaveRequestsApprovalsPagination.vue";
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";

export default {
    components: {
        FormAction, CreateHolidayRequestForm,
        LeaveRequestsApprovalsPagination,
        HrNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,

    },
    data() {
        return {
            slotProps: null,
            leaveRequests: null,
            departments: null,
            contracts: null,
            projects:null,
            leaveRequestsOpen: true,
            contractsOpen: false,
            projectsOpen: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },


    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        loadApprovals(response){
            this.leaveRequests = response.body.entities.filter(entity => entity.rels.contains('holiday-request'));
        },
        toggleLeaveRequestsOpen(){
            this.leaveRequestsOpen = !this.leaveRequestsOpen;
        },
        toggleContractsOpen(){
            this.contractsOpen = !this.contractsOpen;
        },
        toggleProjectsOpen(){
            this.projectsOpen = !this.projectsOpen;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_light"
        }
    }
}
</script>

